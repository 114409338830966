import * as React from 'react';
import Page from "../layouts/Page";
import IllustrationShare from "../media/illustration/Share.png";

export default () => { 
  return (
    <Page title="Message envoyé" description="Votre message a bien été envoyé.">
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-6">
              <h1 class="title is-3">Merci de l'intérêt que vous portez à notre entreprise !</h1>
              <h2 className="subtitle">Votre message a été pris en compte. Nous y répondrons dans les plus brefs délais.</h2>
            </div>
            <div class="column is-5 is-offset-1">
              <figure class="image">
                <img src={IllustrationShare} />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};
